/*
 * @Author: 黄孝娟
 * @Date: 2019-11-08 14:48:19
 * @LastEditors  : 刘格优
 * @LastEditTime : 2020-01-19 19:48:21
 */
import axios from '@/lib/api.request'

// 名片 GET
export const selectUserCard = params => axios.get('/abt/abtComMuserCard/selectUserCard', {
  params
})
// 计划书名片
export const getpropictures = params => axios.get('/jhs/product/getpropictures', {
  params
})
// 编辑名片 POST
export const updateCard = data => axios.put('/abt/abtComMuserCard/updateCard', data)
// 性别下拉框
export const selectMJ = data => axios.post('/saas/select/selectMJ', data)
// 保存手机号 POST
export const exchangeMobile = data => axios.post('/abt/abtComMuserCard/exchangeMobile', data)
// 列表
export const getexchangeMobileList = params => axios.get('/abt/abtComMuserCard/getexchangeMobileList', {
  params
})
// 产品详情
export const comshow = params => axios.get('/hx/abtComHxClassList/show', {
  params
})
// 点赞
export const updateDZ = data => axios.post('/abt/abtComKjActionRecord/updateDZ', data)
